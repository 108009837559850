<template>
  <b-container fluid>
    <b-row>
      <div class="content-img-login"><div class="img-login"></div></div>
      <b-col cols="12" lg="3" class="lateral-login-left">
        <div class="content-end-center logo-login-m">
          <img class="img-logo-login" src="@/assets/images/Logo_ProgreSER_M.png" alt="Logo" />
        </div>
      </b-col>
      <b-col cols="12" lg="9" class="lateral-login-right">
        <div class="content-end-center logo-login-w">
          <img class="img-logo-login" src="@/assets/images/Logo_ProgreSER_W.png" alt="Logo" />
        </div>
        <b-container fluid class="pd-content-login">
          <p class="text-white fs-40 mb-0 lh-1">USUARIO</p>
          <p class="text-white fs-40 lh-1"><strong>REGISTRADO</strong></p>
          <b-row>
            <b-col cols="12" sm="8" xl="9">
              <b-form-group class="text-left">
                <b-form-input
                  name="bloquearPegado"
                  class="custom-input-login"
                  v-model="$v.formL.usuario.$model"
                  placeholder="NÚMERO DE IDENTIFICACIÓN"
                  :state="$v.formL.usuario.$dirty ? !$v.formL.usuario.$error : null"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="8" xl="9">
              <b-input-group class="text-left">
                <b-form-input
                  name="bloquearPegado"
                  placeholder="CONTRASEÑA"
                  v-model="$v.formL.clave.$model"
                  :type="verClave ? 'text' : 'password'"
                  class="custom-input-login custom-input-group"
                  :state="$v.formL.clave.$dirty ? !$v.formL.clave.$error : null"
                />
                <b-input-group-append @click="visualizarClave()">
                  <b-button class="icono-ver-clave px-3" style="background-color: #1806ea9f;">
                    <i :class="`las la-eye${verClave ? '-slash' : ''} fs-22 text-white d-flex`"></i>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col cols="12" sm="4" xl="3" class="content-end-start">
              <b-button variant="danger" @click="login(null)">INGRESAR</b-button>
            </b-col>
          </b-row>
          <p class="text-white fs-14 ls-3 mb-0 pt-3 content-start-center">
            <i class="las la-caret-right fs-18 info"></i>
            <router-link class="text-white" to="/recuperar-clave">¿NO RECUERDAS TU CONTRASEÑA?</router-link>
          </p>
          <hr class="hr-home ml-0 border-white" />
          <p class="text-white fs-14 ls-3 mb-0 content-start-center">
            <i class="las la-user fs-18 info"></i> USUARIO NUEVO
            <b-button class="ml-2 ls-0" variant="info" tag="router-link" to="/registro">REGISTRARSE</b-button>
          </p>
          <div class="py-3">
            <img src="@/assets/images/Titulo_Login.png" class="img-title-login" alt="sucursal virtual" />
          </div>
        </b-container>
      </b-col>
    </b-row>
    <ModalAuthOtp
      :modal="modal.otp"
      @useModal="useModal"
      @sendForm="validarCodigoOtp"
      @generarCodigoOtp="generarCodigoOtp"
    />
    <ModalCambiarClave :modal="modal.clave" @useModal="useModal" @sendForm="retryLogin" />
    <ModalInformativoInicial :modal="modal.infoInicial" :infoModal="infoModalInicial" @useModal="useModal" />
  </b-container>
</template>

<script>
const signalR = require("@microsoft/signalr");
import PublicService from "@/app/core/api/public";
import AuditoriaService from "@/app/core/api/auditoria";
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import AlertsService from "@/app/shared/services/alerts.service";
import { required, numeric, maxLength } from "vuelidate/lib/validators";
import EnvironmentService from "@/app/shared/services/environment.service";

// COMPONENTS
import ModalAuthOtp from "../components/ModalAuthOtp.vue";
import ModalCambiarClave from "../components/ModalCambiarClave.vue";
import ModalInformativoInicial from "@/app/core/components/ModalInformativoInicial.vue";

export default {
  mixins: [mixinsEvents],
  components: { ModalAuthOtp, ModalCambiarClave, ModalInformativoInicial },
  data() {
    return {
      verClave: false,
      formL: { usuario: "", clave: "" },
      infoModalInicial: { MENSAJE_MODAL: "", TITULO_MODAL: "" },
      modal: { otp: { isOpen: false }, clave: { isOpen: false }, infoInicial: { isOpen: false } },
    };
  },
  validations: {
    formL: { usuario: { required, numeric, maxLength: maxLength(12) }, clave: { required } },
  },
  beforeCreate() {
    if (this.$route.query.hash !== undefined) {
      const hash = this.$route.query.hash;

      PublicService.validarHash({ codigoHash: hash })
        .then((response) => {
          this.$router.push("/login");
          const dataModal = {
            tipo: "success",
            mensaje: response.data.mensaje,
            titulo: "Registro Sucursal Virtual ProgreSER",
          };
          this.$store.commit("openModalConfirms", dataModal);
        })
        .catch(() => {
          this.$router.push("/login");
        });
    }
  },
  created() {
    PublicService.publicToken().then((response) => {
      this.$store.commit("setPublicToken", response.data.token);
      AuditoriaService.getAccionesAuditoria();
      PublicService.getParametrosIniciales().then((response) => {
        this.$store.dispatch("updateParams", response.data);
        if (response.data.MENSAJE_INICIO_ACTIVACION === "1") {
          this.cargarModalInformativo();
        }
      });
    });
  },
  mounted() {
    document.getElementsByName("bloquearPegado").forEach((elem) => {
      elem.onpaste = function(e) {
        e.preventDefault();
      };
    });
  },
  methods: {
    useModal(modal) {
      this.modal[modal].isOpen = !this.modal[modal].isOpen;
    },
    login(clave = null) {
      this.$v.formL.$touch();
      if (this.$v.formL.$invalid) return;

      const hashClave = clave !== null ? clave : this.getHash(this.formL.clave);
      const body = { ...this.formL, clave: hashClave, ip: this.$store.getters.ip, navegador: navigator.userAgent };

      PublicService.login(body).then((response) => {
        const data = response.data;
        this.$store.commit("setUserData", { identificacion: this.formL.usuario, clave: hashClave });

        if (data.actualizacionDatosRequerida) {
          const info = { cedula: this.formL.usuario, celular: data.numeroCelular, tipoDocumento: data.tipoDocumento };
          this.$store.commit("setDataShared", info);
          this.$router.push("/actualizar-datos-web");
        } else if (data.claveVigente) {
          this.$store.commit("setUserData", data);
          if (data.mensajeLogin) {
            AlertsService.warning("Iniciar Sesión", data.mensajeLogin);
          }

          if (data.dobleAutenticacionRequerida) this.generarCodigoOtp();
          else if (data.guid) {
            this.conectarHubLogout(data.idAcceso, data.requestGrupo.grupo);
            this.$router.push("/home");
          }
        } else {
          let msg = "Su contraseña ha caducado, debe cambiarla para iniciar sesión.";
          AlertsService.success("Cambio de contraseña", msg);
          this.generarCodigoClave();
        }
      });
    },
    retryLogin() {
      this.login(this.$store.getters.clave);
    },
    generarCodigoOtp() {
      PublicService.generarCodigoOtp({ cedula: this.$store.getters.identificacion }).then((response) => {
        if (response.data.estado) {
          this.useModal("otp");
          AlertsService.success("Inicio de sesión", response.data.mensaje);
        }
      });
    },
    validarCodigoOtp(codigo) {
      const body = {
        codigo: codigo,
        ip: this.$store.getters.ip,
        clave: this.getHash(this.formL.clave),
        navegador: window.navigator.userAgent,
        cedula: this.$store.getters.identificacion,
      };
      PublicService.validarCodigoOtp(body).then((response) => {
        const data = response.data;
        this.$store.commit("setUserData", data);
        if (data.guid) {
          this.conectarHubLogout(data.idAcceso, data.requestGrupo.grupo);
          this.$router.push("/home");
        }
      });
    },
    generarCodigoClave() {
      const body = { cedula: this.$store.getters.identificacion, tipo: "EMAIL" };
      PublicService.generarCodigoClave(body).then((response) => {
        if (response.data.estado) {
          this.useModal("clave");
          AlertsService.success("Cambio de contraseña", response.data.mensaje);
        }
      });
    },
    conectarHubLogout(idSesion, idGrupo) {
      let connection = new signalR.HubConnectionBuilder()
        .withUrl(`${EnvironmentService.baseUrl}/chatHub`, { accessTokenFactory: () => this.$store.getters.token })
        .build();

      connection.on("ReceiveMessage", (_user, tipo) => {
        if (tipo === "LOGOUT") {
          this.$router.push("/logout");
        }
      });

      connection.start().then(() => connection.invoke("AddToGroup", idSesion.toString(), idGrupo));
    },
    visualizarClave() {
      this.verClave = !this.verClave;
    },
    cargarModalInformativo() {
      PublicService.getParametrosModalInicial().then((response) => {
        this.infoModalInicial = {
          ...response.data,
          MENSAJE_MODAL: response.data.MENSAJE_MODAL.replace(/\n/g, "<br />"),
        };
        this.useModal("infoInicial");
      });
    },
  },
};
</script>
