var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c("div", { staticClass: "content-img-login" }, [
            _c("div", { staticClass: "img-login" }),
          ]),
          _c(
            "b-col",
            {
              staticClass: "lateral-login-left",
              attrs: { cols: "12", lg: "3" },
            },
            [
              _c("div", { staticClass: "content-end-center logo-login-m" }, [
                _c("img", {
                  staticClass: "img-logo-login",
                  attrs: {
                    src: require("@/assets/images/Logo_ProgreSER_M.png"),
                    alt: "Logo",
                  },
                }),
              ]),
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "lateral-login-right",
              attrs: { cols: "12", lg: "9" },
            },
            [
              _c("div", { staticClass: "content-end-center logo-login-w" }, [
                _c("img", {
                  staticClass: "img-logo-login",
                  attrs: {
                    src: require("@/assets/images/Logo_ProgreSER_W.png"),
                    alt: "Logo",
                  },
                }),
              ]),
              _c(
                "b-container",
                { staticClass: "pd-content-login", attrs: { fluid: "" } },
                [
                  _c("p", { staticClass: "text-white fs-40 mb-0 lh-1" }, [
                    _vm._v("USUARIO"),
                  ]),
                  _c("p", { staticClass: "text-white fs-40 lh-1" }, [
                    _c("strong", [_vm._v("REGISTRADO")]),
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "8", xl: "9" } },
                        [
                          _c(
                            "b-form-group",
                            { staticClass: "text-left" },
                            [
                              _c("b-form-input", {
                                staticClass: "custom-input-login",
                                attrs: {
                                  name: "bloquearPegado",
                                  placeholder: "NÚMERO DE IDENTIFICACIÓN",
                                  state: _vm.$v.formL.usuario.$dirty
                                    ? !_vm.$v.formL.usuario.$error
                                    : null,
                                },
                                model: {
                                  value: _vm.$v.formL.usuario.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.formL.usuario,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.formL.usuario.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "8", xl: "9" } },
                        [
                          _c(
                            "b-input-group",
                            { staticClass: "text-left" },
                            [
                              _c("b-form-input", {
                                staticClass:
                                  "custom-input-login custom-input-group",
                                attrs: {
                                  name: "bloquearPegado",
                                  placeholder: "CONTRASEÑA",
                                  type: _vm.verClave ? "text" : "password",
                                  state: _vm.$v.formL.clave.$dirty
                                    ? !_vm.$v.formL.clave.$error
                                    : null,
                                },
                                model: {
                                  value: _vm.$v.formL.clave.$model,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.$v.formL.clave, "$model", $$v)
                                  },
                                  expression: "$v.formL.clave.$model",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.visualizarClave()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "icono-ver-clave px-3",
                                      staticStyle: {
                                        "background-color": "#1806ea9f",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        class:
                                          "las la-eye" +
                                          (_vm.verClave ? "-slash" : "") +
                                          " fs-22 text-white d-flex",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "content-end-start",
                          attrs: { cols: "12", sm: "4", xl: "3" },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.login(null)
                                },
                              },
                            },
                            [_vm._v("INGRESAR")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-white fs-14 ls-3 mb-0 pt-3 content-start-center",
                    },
                    [
                      _c("i", { staticClass: "las la-caret-right fs-18 info" }),
                      _c(
                        "router-link",
                        {
                          staticClass: "text-white",
                          attrs: { to: "/recuperar-clave" },
                        },
                        [_vm._v("¿NO RECUERDAS TU CONTRASEÑA?")]
                      ),
                    ],
                    1
                  ),
                  _c("hr", { staticClass: "hr-home ml-0 border-white" }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-white fs-14 ls-3 mb-0 content-start-center",
                    },
                    [
                      _c("i", { staticClass: "las la-user fs-18 info" }),
                      _vm._v(" USUARIO NUEVO "),
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-2 ls-0",
                          attrs: {
                            variant: "info",
                            tag: "router-link",
                            to: "/registro",
                          },
                        },
                        [_vm._v("REGISTRARSE")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "py-3" }, [
                    _c("img", {
                      staticClass: "img-title-login",
                      attrs: {
                        src: require("@/assets/images/Titulo_Login.png"),
                        alt: "sucursal virtual",
                      },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ModalAuthOtp", {
        attrs: { modal: _vm.modal.otp },
        on: {
          useModal: _vm.useModal,
          sendForm: _vm.validarCodigoOtp,
          generarCodigoOtp: _vm.generarCodigoOtp,
        },
      }),
      _c("ModalCambiarClave", {
        attrs: { modal: _vm.modal.clave },
        on: { useModal: _vm.useModal, sendForm: _vm.retryLogin },
      }),
      _c("ModalInformativoInicial", {
        attrs: {
          modal: _vm.modal.infoInicial,
          infoModal: _vm.infoModalInicial,
        },
        on: { useModal: _vm.useModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }