var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { centered: "", "no-close-on-backdrop": "" },
      on: {
        close: function ($event) {
          return _vm.closeModal()
        },
        hidden: function ($event) {
          return _vm.closeModal()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [_c("strong", [_vm._v(_vm._s(_vm.infoModal.TITULO_MODAL))])]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-col",
                { staticClass: "content-center", attrs: { cols: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "danger",
                        target: "_blank",
                        href: "https://progreser.com/contactenos/",
                      },
                    },
                    [_vm._v("Más Información")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.modal.isOpen,
        callback: function ($$v) {
          _vm.$set(_vm.modal, "isOpen", $$v)
        },
        expression: "modal.isOpen",
      },
    },
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("p", {
              staticClass: "px-4",
              domProps: { innerHTML: _vm._s(_vm.infoModal.MENSAJE_MODAL) },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }