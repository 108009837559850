<template>
  <b-modal size="sm" centered hide-footer v-model="modal.isOpen" @close="onCloseModal()" @hidden="onHiddeModal()">
    <template #modal-title>
      Ingresar OTP
    </template>
    <div class="py-4">
      <b-row>
        <b-col cols="12" class="pb-2">
          <b-form-group label="Código OTP">
            <b-form-input v-model="$v.codigo.$model" :state="$v.codigo.$dirty ? !$v.codigo.$error : null" />
            <b-form-invalid-feedback>{{ validarCampo($v.codigo) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col sm="12" class="d-flex justify-content-center">
          <b-button variant="danger" @click="sendForm()">
            Ingresar
          </b-button>
        </b-col>
        <b-col sm="12" class="d-flex justify-content-center pt-2">
          <b-button variant="link" class="primary" @click="$emit('generarCodigoOtp')">
            Reenviar OTP
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import { required, numeric, maxLength } from "vuelidate/lib/validators";

export default {
  mixins: [mixinsEvents],
  props: {
    modal: {
      type: Object,
      default: () => {
        return { isOpen: false };
      },
    },
  },
  data() {
    return {
      codigo: null,
    };
  },
  validations: {
    codigo: { required, numeric, maxLength: maxLength(4) },
  },
  methods: {
    sendForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$emit("sendForm", this.codigo);
      this.onCloseModal();
    },
    onCloseModal() {
      this.$emit("useModal", "otp");
    },
    onHiddeModal() {
      this.codigo = null;
      setTimeout(() => this.$v.$reset(), 0);
    },
  },
};
</script>
