<template>
  <b-modal centered no-close-on-backdrop @close="closeModal()" v-model="modal.isOpen" @hidden="closeModal()">
    <template #modal-title>
      <strong>{{ infoModal.TITULO_MODAL }}</strong>
    </template>
    <b-row>
      <b-col cols="12">
        <p class="px-4" v-html="infoModal.MENSAJE_MODAL" />
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-col cols="12" class="content-center">
        <b-button variant="danger" target="_blank" href="https://progreser.com/contactenos/">Más Información</b-button>
      </b-col>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    modal: {
      type: Object,
      default: () => {
        return { isOpen: false };
      },
    },
    infoModal: {
      type: Object,
      default: () => {
        return { MENSAJE_MODAL: "", TITULO_MODAL: "" };
      },
    },
  },
  methods: {
    closeModal() {
      // this.$store.commit("closeModalConfirms");
    },
  },
};
</script>
